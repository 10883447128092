import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"

const SingleProject = () => {
  return (
    <div>
      <Layout>
        <div className="flex flex-col items-center justify-center py-24 px-6 lg:px-16 case_study_bg text-white lg:h-96">
          <h1 className="text-3xl lg:text-6xl font-bold text-center">
            SAMSUNG
          </h1>
          <p className="lg:w-100 text-xl font-thin pt-8"></p>
        </div>
        <div className="flex items-center justify-center py-8 px-6 lg:px-32">
          <article className=" font-thin py-16 w-full ">
            <div className="flex lg:flex-row flex-col">
              <div className="flex flex-col w-full lg:w-1/2  pr-0 lg:pr-12">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/Samsung-logo-2.jpeg"
                  placeholder="blurred"
                  className="my-4 h-72"
                  layout="fullWidth"
                />

                <h1 className="text-xl font-bold items-center ">
                  CAMPAIGN GOAL{" "}
                </h1>

                <div className="">
                  <p className="font-light">
                    Engage Samsung Fans by building the Samsung mobile fan page
                    and leveraging radio stations, as well as an engaging social
                    media platform.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2   pt-8 lg:pt-0">
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/Samsung-Logo.jpeg"
                  placeholder="blurred"
                  className="my-4 h-72"
                  layout="fullWidth"
                /> */}
                <h1 className="text-xl font-bold items-center ">CONVERSION</h1>

                <div className="">
                  <p className="font-light">
                    {" "}
                    We teamed up with Beat 99.9fm which has the highest social
                    media followership and engagement of all the radio stations.
                    We also teamed up with a trendy Nightclub to host Samsung
                    fans with a night of free drinks and music acts.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col pt-12">
              {/* <div className="flex flex-col w-full lg:w-1/2 pr-0 lg:pr-12">
                <h1 className="text-xl font-bold items-center ">CONVERSION</h1>
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/6075-302353-Samsung-Galaxy-S-II-I9100.jpg"
                  placeholder="blurred"
                  className="my-4"
                />
                <div className="">
                  <p className="font-light">
                    {" "}
                    We teamed up with Beat 99.9fm which has the highest social
                    media followership and engagement of all the radio stations.
                    We also teamed up with a trendy Nightclub to host Samsung
                    fans with a night of free drinks and music acts.
                  </p>
                </div>
              </div> */}

              <div className="flex flex-col w-full lg:w-1/2 pt-8 lg:pt-0">
                <h1 className="text-xl font-bold items-center ">RESULT</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../images/clients/mediaseal_2.jpeg"
                  placeholder="blurred"
                  className="my-4"
                /> */}
                <div className="">
                  <p className="font-light">
                    The Samsung mobile fan page grew from 3,000+ to 66,000+
                    within the campaign period.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    </div>
  )
}

export default SingleProject
